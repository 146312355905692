import HttpClient from '@/services/api/ApiBase';
import { RoleManagementDetails } from '@/store/models/RoleManagementDetails';
import { PartnerRole } from './api/models/PartnerRole';

export const AssignRole = async (request: {
  userId: number,
  roleId: number,
  partnerId: number
}): Promise<void> => {
  await HttpClient.post(
    `/partner/${request.partnerId}/roles/assign`, 
    {
      roleId: request.roleId,
      userId: request.userId
    }
  );
}

export const GetRoleManagementDetails = async (request: {
  partnerId: number,
  productType: string
}): Promise<RoleManagementDetails> => {
  const response = await HttpClient.get<RoleManagementDetails>(
    `/partner/${request.partnerId}/roles/${request.productType}`
  );
  return response.data;
}

export const ToggleRolePermission = async (request: {
  partnerId: number,
  roleId: number,
  permissionId: number
}): Promise<void> => {
  await HttpClient.patch(
    `/partner/${request.partnerId}/roles/permission`,
    {
      roleId: request.roleId,
      permissionId: request.permissionId
    }
  );
}

export const CreateRole = async (request: {
  partnerId: number,
  roleName: string
}): Promise<PartnerRole[]> => {
  const response = await HttpClient.post<PartnerRole[]>(
    `/partner/${request.partnerId}/roles/create`,
    { roleTitle: request.roleName }
  );
  return response.data;
}

export const DeleteRole = async (request: {
  partnerId: number,
  roleId: number
}): Promise<void> => {
  await HttpClient.delete(
    `/partner/${request.partnerId}/roles/${request.roleId}`
  );
}