
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DropdownOption from '@/store/models/DropdownOption';

interface InputAttributes {
  id: string;
  label: string;
  labelClass: string;
  type: string;
  autocomplete: string;
  state: () => boolean | null;
  errorMessage: string;
  placeholder: string;
  value: string | boolean;
  options: (string | boolean | DropdownOption)[];
  variant: string;
}

@Component({})
export default class FormDropdownInput extends Vue {
  @Prop() inputAttrs!: InputAttributes;
  public selected: string | boolean | DropdownOption = 'Select';

  mounted() {
    this.selected = this.inputAttrs.value;
  }

  @Watch('inputAttrs', { deep: true })
  updateValue() {
    this.selected = this.inputAttrs.value;
  }

  public getLabel(option: string | boolean | DropdownOption) {
    return typeof option === 'string' || typeof option === 'boolean' || !option
      ? option
      : option.label;
  }

  public changed(select: string | DropdownOption) {
    this.selected = select;
    this.updateInput(select);
  }

  public updateInput(newValue: string | boolean | DropdownOption) {
    this.$emit('input', newValue);
  }
}
