import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSettingsUserAdd extends GAEvent {
  public eventName = 'settings-user-add';
  public roleId?: number;

  constructor(roleId?: number) {
    super();
    this.roleId = roleId;
  }
}
