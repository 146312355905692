
import { Component, Vue } from 'vue-property-decorator';
import FormTextInput from '@/components/common/FormTextInput.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import { Validation, validationMixin } from 'vuelidate';
import { required, email, integer } from 'vuelidate/lib/validators';
import ApiError from '@/services/api/models/ApiError';
import { CreatePartnerUser, UpdatePartnerUsers } from '@/services/api/UsersApi';
import User from '@/store/modules/User';
import { trackEvent } from '@/services/Mixpanel';
import { mixins } from 'vue-class-component';
import AppName from '@/components/mixin/AppName.vue';
import PhoneNumberInput from '@/components/items/PhoneNumberInput.vue';
import { AxiosError } from 'axios';
import DropdownOption from '@/store/models/DropdownOption';
import { AssignRole, GetRoleManagementDetails } from '@/services/RolesApi';
import { PartnerRole } from '@/services/api/models/PartnerRole';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSettingsUserUpdate } from '@/services/ga/events/EventSettingsUserUpdate';
import { EventSettingsUserAdd } from '@/services/ga/events/EventSettingsUserAdd';

interface NewUserForm {
  name: string;
  email: string;
  phone: string;
  role: DropdownOption | null;
}

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    PhoneNumberInput
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      role: {
        required,
        integer
      }
    }
  },
  mixins: [validationMixin]
})
export default class AddUser extends mixins(AppName) {
  public error: ApiError | null = null;
  public form: NewUserForm = { name: '', email: '', phone: '', role: null };
  public submitted = false;
  public unresolvableError = false;
  public errorMessage = 'Missing fields';
  public errorOccurred = false;
  public app = 'milk';
  public colour = '#F6861F';
  public bootstrapColour = 'secondary';
  public width = 1000;

  public roles: PartnerRole[] | null = null;
  public loading = false;

  public get roleOptions(): DropdownOption[] {
    if (!this.roles) {
      return [];
    }
    return this.roles.map(r => ({ value: r.id, label: r.title }));
  }

  mounted() {
    trackEvent('User viewing add user');

    this.app = this.getAppName(false);
    this.colour = this.getAppColour(false);
    this.bootstrapColour = this.getAppColour(true);
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    this.loadRoleManagementDetails();
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  public async onSubmit(event: any) {
    event.preventDefault();
    this.$v.form.$touch();
    if (this.$v.form.$anyError && !this.validateForm()) {
      this.errorOccurred = true;
      return;
    }
    this.errorOccurred = false;

    const { role, ...rest } = this.form;

    const response = await CreatePartnerUser({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      params: {
        ...rest,
        role: 'partner.user',
        partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0
      }
    });
    if (response == -1) {
      this.unresolvableError = true;
    } else if (role) {
      await this.assignUserRole(response.data.id, role.value as number);
    }
    this.submitted = true;
    this.$root.$emit('usersUpdated');
    this.$bvToast.toast(
      this.unresolvableError
        ? `${this.form.name} has not been added`
        : `${this.form.name} has been added, password set to 'password'`,
      {
        title: this.unresolvableError ? 'User not added' : 'User added',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      }
    );
    trackEvent('User added user');

    GA.event<EventSettingsUserAdd>(
      this.$gtag,
      new EventSettingsUserAdd(role?.value as number)
    );

    this.$root.$emit('usersUpdated');
    this.$router.push(
      `/${this.getAppName(false)}-settings/tab/user-management`
    );
  }

  public validateForm(): boolean | null {
    if (this.form.name.length == 0 && this.form.name != ' ') {
      this.errorMessage = "Please enter the user's name";
      return false;
    }
    if (this.form.email.length == 0) {
      this.errorMessage = "Please enter the user's email";
      return false;
    }
    if (this.form.phone == null) {
      this.errorMessage = 'Phone number invalid';
      return false;
    }
    if (this.form.role == null) {
      this.errorMessage = "Please enter the user's role";
      return false;
    }

    return true;
  }

  private async loadRoleManagementDetails(): Promise<void> {
    this.loading = true;
    const appName = this.getAppName(false);
    try {
      const roleManagement = await GetRoleManagementDetails({
        partnerId: this.currentOrgId(),
        productType: appName
      });
      this.roles = roleManagement.roles;
      const readonlyRole: PartnerRole | null =
        this.roles.find(r => r.title === 'Read only') ?? null;
      if (readonlyRole) {
        this.form.role = { value: readonlyRole.id, label: readonlyRole.title };
      }
    } catch (e) {
      const error = e as AxiosError;
      this.errorMessage =
        error.response?.data.errors ??
        'There was a problem fetching the available roles. Please try again.';
    } finally {
      this.loading = false;
    }
  }

  private async assignUserRole(userId: number, roleId: number): Promise<void> {
    try {
      await AssignRole({
        partnerId: this.currentOrgId(),
        userId,
        roleId
      });
    } catch (e) {
      const error = e as ApiError;
      this.$bvToast.toast(
        `There was a problem adding this user - ${error.errorMessage}`,
        {
          title: 'Error adding user',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false
        }
      );
    }
  }

  private currentOrgId(): number {
    return User._token?.orgs[User._orgIndex].orgId ?? 0;
  }
}
